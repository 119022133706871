import { ref } from 'vue';

export const useDropdown = (
    targetElement: Ref<HTMLElement | null>,
    dropdownElement: Ref<HTMLElement | null>,
) => {
    let animationFrameId: number;
    const openTop = ref(false);
    const openLeft = ref(false);

    const watchDropdownPosition = () => {
        if (targetElement.value && dropdownElement.value) {
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            const distanceToBottom =
                viewportHeight -
                targetElement.value.getBoundingClientRect().bottom;
            const distanceToRight =
                viewportWidth -
                targetElement.value.getBoundingClientRect().right;

            openTop.value =
                distanceToBottom <
                dropdownElement.value.getBoundingClientRect().height;

            openLeft.value =
                distanceToRight <
                dropdownElement.value.getBoundingClientRect().width;
        }

        animationFrameId = requestAnimationFrame(watchDropdownPosition);
    };

    const initDropdownPositionWatcher = () => {
        animationFrameId = requestAnimationFrame(watchDropdownPosition);
    };

    const cancelDropdownPositionWatcher = () => {
        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
        }
    };

    return {
        cancelDropdownPositionWatcher,
        initDropdownPositionWatcher,
        openLeft,
        openTop,
    };
};
